import DOMPurify from 'dompurify';

if (window.trustedTypes?.createPolicy) {
  const sanitize = (source: string) => DOMPurify.sanitize(source, { RETURN_TRUSTED_TYPE: true });
  window.trustedTypes.createPolicy('default', {
    // @ts-expect-error https://github.com/microsoft/TypeScript/issues/30024
    createHTML: sanitize,
    // @ts-expect-error https://github.com/microsoft/TypeScript/issues/30024
    createScript: sanitize,
    // @ts-expect-error https://github.com/microsoft/TypeScript/issues/30024
    createScriptURL: sanitize,
  });
}
